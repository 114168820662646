import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field is-horizontal" }
const _hoisted_2 = { class: "field-body" }
const _hoisted_3 = { class: "field" }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = { class: "control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('category.NAME')), 1),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "input",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event))
          }, null, 512), [
            [_vModelText, _ctx.form.name]
          ])
        ])
      ])
    ])
  ]))
}