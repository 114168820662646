
import {defineComponent, toRefs} from 'vue';

export default defineComponent({
    props: ['filters'],

    setup(props) {
        const { filters } = toRefs(props);

        return {
            form: filters,
        }
    }
});
