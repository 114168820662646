import ApiResource, { IModel } from '@/resources/ApiResource';

class CategoryResource extends ApiResource {}

export default new CategoryResource('/category');

export interface ICategoryModel extends IModel {
    id: number;
    name: string;
}
