
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import CategoryResource from '@/resources/CategoryResource';
import Filters from '@/components/Category/Filters.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import TableFilters from '@/components/TableFilters.vue';
import TableToolbar from '@/components/TableToolbar.vue';
import useList from '@/composables/use-list';

export default defineComponent({
    components: { Filters, Pagination, TableFilters, TableToolbar },

    setup() {
        const filters = reactive({
            name: undefined,
        });
        const { state, fetch, pagination, removeItem } = useList(CategoryResource);
        const list = toRefs(state);

        function submitFilters() {
            fetch({ page: 1, filters });
        }

        function clearFilters() {
            Object.assign(filters, {
                name: undefined,
            });

            fetch();
        }

        onMounted(() => {
            fetch();
        });

        return {
            filters,
            clearFilters,
            submitFilters,
            items: list.items,
            pagination,
            removeItem,
        };
    },
});
